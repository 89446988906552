import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite
//import { Fancybox } from 'https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.esm.js';
import Swiper from 'https://unpkg.com/swiper@8/swiper-bundle.esm.browser.min.js';

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

// Fancybox.bind('[data-fancybox]', {
// 	// Your options go here
// });

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//

const tabTriggers = document.querySelectorAll('.tabs-nav span');

[...tabTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchTab);
});

function switchTab(e) {
	let targetTab = e.currentTarget.dataset.tab;

	removeActive('.tab[data-active]');
	removeActive('.tabs-nav button[data-active]');

	e.currentTarget.dataset.active = true;
	document.querySelector(`.tab[data-tab='${targetTab}']`).dataset.active = true;
}

//------------------------------------//
// TABS - SELECT
//------------------------------------//
// const inputTrigger = document.querySelector('select.tabs-nav');

// inputTrigger &&
// 	inputTrigger.addEventListener('change', (e) => {
// 		e.preventDefault();

// 		let elTarget = e.currentTarget.value;

// 		removeActive('.tab[data-active]');

// 		//trigger.dataset.active = true;
// 		document.querySelector(
// 			`.tab[data-tab='${elTarget}']`
// 		).dataset.active = true;
// 	});

//------------------------------------//
// SWIPER - NEWS
//------------------------------------//
// const swiperNews = new Swiper('.swiper-news', {
// 	// Optional parameters
// 	loop: true,
// 	slidesPerView: 1.25,
// 	spaceBetween: 20,

// 	// If we need pagination
// 	pagination: {
// 		el: '.grid .swiper-pagination',
// 		type: 'fraction'
// 	},

// 	// Navigation arrows
// 	navigation: {
// 		nextEl: '.grid .button-next',
// 		prevEl: '.grid .button-prev'
// 	},
// 	breakpoints: {
// 		// when window width is >= 320px
// 		600: {
// 			slidesPerView: 1.5
// 		},
// 		// when window width is >= 480px
// 		768: {
// 			slidesPerView: 1.75
// 		},
// 		// when window width is >= 640px
// 		992: {
// 			slidesPerView: 2,
// 			spaceBetween: 30
// 		},
// 		1024: {
// 			slidesPerView: 2.5,
// 			spaceBetween: 50
// 		}
// 	}
// });

//------------------------------------//
// SWIPER - HERO
//------------------------------------//
const swiperHero = new Swiper('.swiper-hero', {
	// Optional parameters
	loop: true,
	slidesPerView: 1,
	effect: 'fade',
	autoplay: {
		delay: 3000
	},

	// If we need pagination
	pagination: {
		el: '.hero .swiper-pagination'
	}
});

//------------------------------------//
// SWIPER - Content
//------------------------------------//
const swiperContent = new Swiper('.swiper-content', {
	// Optional parameters
	loop: true,
	slidesPerView: 1,
	effect: 'fade',
	speed: 250,
	autoplay: {
		delay: 1000
	}
});

//------------------------------------//
// SWIPER - Review
//------------------------------------//
const swiperReviews = new Swiper('.swiper-reviews', {
	// Optional parameters
	loop: true,
	slidesPerView: 1,
	spaceBetween: 100,

	// Navigation arrows
	navigation: {
		nextEl: '.reviews .swiper-next',
		prevEl: '.reviews .swiper-prev'
	}
});

//------------------------------------//
// SWIPER - Profiels
//------------------------------------//
const swiperProfiels = new Swiper('.swiper-profiles', {
	// Optional parameters
	loop: false,
	slidesPerView: 1,
	spaceBetween: 20,

	// Navigation arrows
	navigation: {
		nextEl: '.profiles .swiper-next',
		prevEl: '.profiles .swiper-prev'
	},
	breakpoints: {
		640: {
			slidesPerView: 1,
		},
		768: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 4,
		},
	},
});

//------------------------------------//
// SWIPER - Gallery
//------------------------------------//
const swiperGallery = new Swiper('.swiper-gallery', {
	// Optional parameters
	loop: true,
	slidesPerView: 1,
	spaceBetween: 20,

	// Navigation arrows
	navigation: {
		nextEl: '.gallery .swiper-next',
		prevEl: '.gallery .swiper-prev'
	},
	breakpoints: {
		640: {
			slidesPerView: 1,
		},
		768: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 4,
		},
	},
});
